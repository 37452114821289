export const forane ={
    "CHELAKKARA": [
      "CHELAKKARA",
      "CHERUTHURUTHY",
      "ELANAD",
      "KILLIMANGALAM",
      "MANALADY",
      "MAYANNUR",
      "MULLURKARA",
      "PANGARAPILLY",
      "PARAKKAD",
      "PAZHAYANNUR",
      "THIRUVILWAMALA"
    ],
    "ERUMAPETTY": [
      "ATTATHARA",
      "CHOWANNUR",
      "ERUMAPETTY",
      "EYYAL",
      "KADANGODE",
      "MANGAD",
      "MARATHAMKODE",
      "PAREMPADAM",
      "PATHIYARAM",
      "PATHRAMANGALAM",
      "PUTHURUTHY",
      "VELLARAKKAD"
    ],
    "KANDASSANKADAVU": [
      "ARIMPUR",
      "ENAMAKKAL",
      "ENGANDIYUR",
      "ERAVU",
      "KANDASSANKADAVU",
      "KANJANI",
      "KARAMUCK",
      "KARAMUCK NORTH",
      "KIZHAKKUMPURAM",
      "MANAKODY",
      "MANALUR EAST",
      "MANALUR WEST",
      "MULLASSERY",
      "NORTH PUDUKAD",
      "THOYAKKAVU",
      "VADANAPPILLY",
      "VELUTHUR",
      "THALIKULAM"
    ],
    "KOTTEKAD": [
      "AVANUR",
      "CHOOLISSERY",
      "KOLENGATTUKARA",
      "KOTTEKAD",
      "KUTTUR",
      "MUNDUR",
      "PERAMANGALAM",
      "POTTORE",
      "VARADIAM",
      "VELAPPAYA",
      "VIYYUR"
    ],
    "MATTOM": [
      "ALOOR",
      "ALOOR EAST",
      "CHITTATTUKARA",
      "CHOONDAL",
      "ELAVALLY",
      "KANDANASSERY",
      "KOONAMMOOCHY",
      "KUNNAMKULAM",
      "MATTOM",
      "NAMBAZHIKAD",
      "PAZHUNNANA",
      "PUDUSSERY",
      "VAKA"
    ],
    "OLLUR": [
      "AMMADAM",
      "ANCHERY",
      "AVINISSERY",
      "CHIYYARAM",
      "GALILEE",
      "KODANNUR",
      "KONIKKARA",
      "MARATHAKKARA",
      "OLLUR",
      "PADAVARAD",
      "PALAKKAL",
      "PALLISSERY",
      "OLLUR MARYMATHA",
      "PERINCHERY",
      "POOTHARAKKAL",
      "PULAKKATTUKARA",
      "THAIKKATTUSSERY",
      "THALORE",
      "VALLACHIRA",
      "VENGINISSERY"
    ],
    "PALAYUR": [
      "ANJOOR",
      "ARTHAT",
      "ATTUPURAM",
      "BRAHMAKULAM",
      "CHEMMANNUR",
      "GURUVAYUR",
      "KAKKASSSERY",
      "KAVEED",
      "KOTTAPADY",
      "ORUMANAYUR",
      "PALAYUR",
      "PAVARATTY",
      "PERAKAM",
      "PERINGAD",
      "PONNANI",
      "VYLATHUR"
    ],
    "PARAPPUR": [
      "ADAT",
      "AMALA NAGAR",
      "AMBAKAD",
      "CHITTILAPPILLY",
      "EDAKKALATHUR",
      "PARAPPUR",
      "PERUVALLUR",
      "PONNORE",
      "PURANATTUKARA",
      "THOLOOR"
    ],
    "PATTIKAD": [
      "CHERUMKUZHY",
      "CHIRAKKAKODE",
      "CHUVANNAMANNU",
      "KANNARA",
      "KOOTTALA",
      "MANJAKKUNNU",
      "MULAYAM",
      "PATTIKAD",
      "PEECHI",
      "VANIAMPARA",
      "VEENDASSERY",
      "VELLAKARITHADAM"
    ],
    "PAZHUVIL": [
      "ANTHIKAD",
      "CHAZHUR",
      "CHERPU",
      "CHEVOOR",
      "CHIRAKKAL",
      "KIZHUPPILLIKKARA",
      "MUTTICHUR",
      "NATTIKA",
      "PAZHUVIL",
      "PERINGOTTUKARA",
      "POOCHINNIPADAM",
      "PORATH"
    ]
  };
  