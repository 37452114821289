import React, { useState, useEffect } from "react";
import "../css/register.css";
import axios from "axios";
import  {forane}  from '../comp/forane'
// windows.Header().Set("Access-Control-Allow-Origin", "*")
export default function Register1() {
  // const [data, setData] = useState({});
  const [form, setForm] = useState({
    name: "",
    mobile: "",
    email: "",
    class: "",
    forane: "",
    unit: "",
    gender: "",
    competation: "",
  });
  let data = forane ;
  // console.log(data);
  let units = data[`${form.forane}`];
  //  console.log(units);
  // useEffect(() => {
  //   getData();
  //   // console.log('iterate');
  // }, [data]);
  // console.log("fetched data",data["CHELAKKARA"]);
  // const getData = () => {
  //   fetch("Forane.json", {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //       // console.log(response)
  //       return response.json();
  //     })
  //     .then(function (myJson) {
  //       // const dataArray = Object.values(myJson);
  //       // console.log("data array ",dataArray.keys());
  //       setData(myJson);
  //     });
  // };
  const postData = async ()=> {
    let Data = {
      "eventname": "",
      "name": form.name,
      "mobile": form.mobile,
      "email": form.email,
      "forane": form.forane,
      "unit": form.unit,
      "cat": "",
      "class": form.class,
      "img": "",
      "competations": form.competation,
      "gender": form.gender,
    }; 
    let bearer = "aa";
    // try {
    //   // Fetch API call with POST method and configuration
    //   const response = await fetch('https://api.dbclc.com/usereventreg', {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //       'Authorization': `Bearer ${bearer}`,
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(Data)
    //   });

    //   // Check if the response is successful
    //   if (response.ok) {
    //     // Handle successful response
    //     console.log('Data sent successfully!');
    //   } else {
    //     // Handle error response
    //     console.log('Error occurred while sending data.');
    //   }} catch (error) {
    //     // Handle fetch error
    //     console.log('Error occurred while sending data:', error);
    //   }
    // let bearer = "aa";
    const config = {
      // withCredentials:false,
      // mode:'cros',
      headers: {
        Authorization: `Bearer ${bearer}`,
        "CONTENT_TYPE": "application/json",
        // 'Access-Control-Allow-Origin': '*',
        // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      },
    };
    
    // usereventreg
    axios
      .post("https://layinstitute.dbclc.com/api_dbclc/usereventreg",Data,config)

      .then(function (response) {
        // handle success
        // alert(JSON.stringify(response.data));
        // console.log(response.data);
        if (response.data.statusCode === 200) {
          alert("submitted");
        }
      })
      .catch(function (error) {
        // handle error
        alert(error.message);
      });
  };
  const validateForm = () => {
    // Check if the First Name is an Empty string or not.

    if (form.name.length == 0 || form.name.length < 4) {
      alert(" Name can not be empty & atleast 4 characters");
      return true;
    }
    // Check if the phone is an Empty string or not.

    if (form.mobile.length > 10 || form.mobile.length < 10) {
      alert("Invalid Phone number");
      return true;
    }
    // Check if the email is an Empty string or not.
    if (form.email.includes("@",".") == !true || form.email.length == 0) {
      alert("Invalid Email Address ");
      return true;
    }
    // Check if the forane is an Empty string or not.
    if (form.forane.length == 0) {
      alert("select valid option");
      return true;
    }
    // Check if the unit is an Empty string or not.
    if (form.unit.length == 0) {
      alert("select valid option");
      return true;
    }
    // Check if the class is an Empty string or not.
    if (form.class.length == 0) {
      alert("select valid option");
      return true;
    }
    // Check if the gender is an Empty string or not.
    if (form.gender.length == 0) {
      alert("select valid option");
      return true;
    }
    // Check if the competation is an Empty string or not.
    if (form.competation.length == 0) {
      alert("select valid option");
      return true;
    }
  };

  const submitButton = (e) => {
    e.preventDefault();
    let validation = validateForm();
    validation ? console.log("invalid form") : postData();

    // resetButton()
  };
  // const resetButton = () => {
  //   setForm({
  //     name: '',
  //     mobile: '',
  //     email: '',
  //     qualification:'',
  //   });
  // }
  return (
    <div class="p-3  h-100 bg-white " id="contact">
      <div class=" g-3 row   p-3 ">
        <div class=" col-md-12  col-sm-12 col-lg-12 ">
          <div class=" d-flex align-items-center justify-content-center">
            <p class="  heading   m-4 ">Innovative Idea Meet 2023 </p>
          </div>
          <div class="d-flex align-items-center justify-content-center ">
            <p class=" fs-4 text-black ">Registration Form </p>
          </div>
          <div class="d-flex align-items-center justify-content-center  ">
            <p class=" fs-6 text-black ">
              INSTITUTE FOR LAY LEADERSHIP & TRAINING, DBCLC, Archbishop's House
              Compound, East Fort Thrissur, 680 005 Ph. No: 9744941585{" "}
            </p>
          </div>
        </div>
        <div class=" col-md-12  col-sm-12 col-lg-12  d-flex align-items-center justify-content-center">
          <div class="p-3 rounded-4 bg-white shadow-lg">
            {/* <img src="/assets/logos/excel-logo.png" alt="  university" width="180" height="60" class="img-reg-top "/> */}
            <div class="ms-1 my-4">
              <p class=" fs-1 text-black ">Register Here </p>
            </div>
            {/*  registration form */}
            <form class="row g-4  p-0 needs-validation" role="form">
              {/* participant name */}
              <div class="col-md-12 col-lg-6">
                <label for="validationCustom01" class="form-label" required>
                  Participant(s) Name
                </label>
                <input
                  type="text"
                  class="form-control border-black rounded-5 reg"
                  id="validationCustom01"
                  name="name"
                  placeholder="Name of the participent"
                  value={form.name}
                  onChange={(e)=> setForm({ ...form, name: e.target.value })}
                  required
                />
                <div class="valid-feedback">Looks good!</div>
                <div class="invalid-feedback">Please choose a username.</div>
              </div>
              {/* mobile number */}
              <div class="col-md-12 col-lg-6">
                <label for="validationCustom02" class="form-label" required>
                  Mobile Number(whatsapp)
                </label>
                <input
                  id="validationCustom02"
                  type="text"
                  class="form-control border-black rounded-5 reg"
                  name="phone"
                  placeholder="Phone Number"
                  value={form.mobile}
                  onChange={(e)=> setForm({ ...form, mobile: e.target.value })}
                  required
                />
              </div>
              {/* email */}
              <div class="col-md-12 col-lg-6 ">
                <label for="validationCustom03" class="form-label" required>
                  Email
                </label>

                <input
                  id="validationCustom03"
                  type="email"
                  class="form-control border-black rounded-5 reg "
                  name="email"
                  placeholder="E-mail"
                  value={form.email}
                  onChange={(e)=> setForm({ ...form, email: e.target.value })}
                  required
                />
              </div>
              {/* <div class="col-md-12 col-lg-12"> </div> */}
              <div class="col-md-12 col-lg-6 ">
                <label for="forane" class="form-label required" required>
                  Forane
                </label>
                <select
                  class=" form-control form-select form-select-lg border-black rounded-5  fs-6 reg"
                  id="forane"
                  aria-label="select"
                  value={form.forane}
                  onChange={(e)=>
                    setForm({ ...form, forane: e.target.value })
                  }>
                  <option selected> select </option>
                  {data !== null
                    ? Object.entries(data).map((item, index) => {
                        // console.log("inside forane",item);
                        return (
                          <>
                            <option key={index} value={item[0]}>
                              {item[0]}
                            </option>
                          </>
                        );
                      })
                    : null}
                </select>
              </div>
              <div class="col-md-12 col-lg-6 ">
                <label for="unit" class="form-label required" required>
                  Unit
                </label>
                <select
                  class=" form-control form-select form-select-lg border-black rounded-5 fs-6 reg"
                  id="unit"
                  aria-label="Default select example"
                  value={form.unit}
                  onChange={(e)=> setForm({ ...form, unit: e.target.value })}>
                  <option selected> select </option>

                  {data !== null && units !== undefined
                    ? units.map((item, index) => {
                        // console.log("inside unit",item);
                        return (
                          <>
                            <option key={index} value={item}>
                              {item}
                            </option>
                          </>
                        );
                      })
                    : null}
                </select>
              </div>
              {/* competation */}
              <div class="col-md-12 col-lg-6 ">
                <label class="form-label"> Competations </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="cate1"
                    name="competation"
                    value="Poetry"
                    onChange={(e)=>
                      setForm({ ...form, competation: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="cate1">
                    Poetry
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="cate2"
                    name="competation"
                    value="Short Story"
                    onChange={(e)=>
                      setForm({ ...form, competation: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="cate2">
                    Short Story
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="cate3"
                    name="competation"
                    value="Eassy Writting"
                    onChange={(e)=>
                      setForm({ ...form, competation: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="cate3">
                    Eassy Writting
                  </label>
                </div>
              </div>
              {/* gender */}
              <div class="col-md-12 col-lg-6 ">
                <label class="form-label"> Gender </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="gender1"
                    name="gender"
                    value="Male"
                    onChange={(e)=>
                      setForm({ ...form, gender: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="gender1">
                    Male
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="gender2"
                    name="gender"
                    value="Female"
                    onChange={(e) =>
                      setForm({ ...form, gender: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="gender2">
                    Female
                  </label>
                </div>
                {/* <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="cate3"
                    value="individual"
                    onChange={(e) =>
                      setForm({ ...form, competation: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="cate3">
                    Eassy Writting
                  </label>
                </div> */}
              </div>
              {/* file input  */}
              {/* <div class="col-md-12 col-lg-6">
                <label
                  for="formFileMultiple"
                  class="form-label required"
                  required>
                  UPLOAD YOUR IDEA ( Kindly note that, your Idea has to be
                  Attested by Catechism Principal & Keep your name as the File
                  Name)
                </label>
                <input
                  class="form-control form-control-lg border-black rounded-5 "
                  type="file"
                  id="formFileMultiple"
                  multiple
                  value={form.file}
                  onChange={(e) => setForm({ ...form, file: e.target.value })}
                  required
                />
              </div> */}
              {/* class */}
              <div class="col-md-12 col-lg-6 ">
                <label class="form-label" required>                
                  Class
                </label>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="class1"
                    name="classname"
                    value="11"
                    onChange={(e)=>
                      setForm({ ...form, class: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="class1">
                    11
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="class2"
                    name="classname"
                    value="12"
                    onChange={(e) =>
                      setForm({ ...form, class: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="class2">
                    12
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="class3"
                    name="classname"
                    value="Acc"
                    onChange={(e) =>
                      setForm({ ...form, class: e.target.value })
                    }
                  />
                  <label class="form-check-label" for="class3">
                    Acc
                  </label>
                </div>
              </div>
              {/* submit button */}
              <div class="col-12 d-flex justify-content-center  ">
                <button
                  class="btn btn-outline-primary btn-lg my-3"
                  type="submit"
                  onClick={submitButton}>
                  Register{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
