import React, { useState, useEffect } from "react";
import axios from "axios";
import '../css/enrolled.css'
import ExportExcel from "../custom components/ExcelExport";
export default function Enrolled() {
  const [list, setList] = useState([]);


  useEffect( ()=>{
      axios
     .get("https://layinstitute.dbclc.com/api_dbclc/eventlist")
     .then((res)=>{
       if(res.data.statusCode===201){
        //  console.log( res.data.data);
         let datalist = res.data.data;
            setList(datalist)
       }
     })
     .catch(function (error) {
         // handle error
         console.log(error)
       })},[]);
       
    //    console.log(list);

      const StudComp =(props)=>{
        return(
            <div class="row border border-sm">
    <div class="col-md-4 col-lg-1 border border-sm">
      <p class=" text-center">{props.slno}</p>
      </div>
     <div class="col-md-4 col-lg-2 border border-sm">
      <p>{props.name}</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p>{props.mobile}</p>
      </div>
      <div class="col-md-4 col-lg-2 border border-sm">
      <p>{props.email}</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p>{props.class}</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p>{props.gender}</p>
      </div>
      <div class="col-md-4 col-lg-2 border border-sm">
      <p>{props.competation}</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p>  {props.forane} </p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p>  {props.unit}</p>
      </div>     
    </div>
        )
      }

  return (
    <div class=" main w-100 p-1 d-flex flex-column  align-items-center">
         <div class="p-3 w-100 border border-sm"> 
<ExportExcel excelData={list} fileName={"Students_list"}/>
    </div>
    <div class="row w-100 border border-sm">      
    <div class="col-md-4 col-lg-1 border border-sm ">
      <p class=" text-center fs-5 fs-5"> Sl.no</p>
      </div>
     <div class="col-md-4 col-lg-2 border border-sm">
      <p class=" text-center fs-5"> Name</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p class=" text-center fs-5"> Mobile</p>
      </div>
      <div class="col-md-4 col-lg-2 border border-sm">
      <p class=" text-center fs-5"> E mail</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p class=" text-center fs-5"> Class</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p class=" text-center fs-5"> Gender</p>
      </div>
      <div class="col-md-4 col-lg-2 border border-sm">
      <p class=" text-center fs-5"> Competation</p>
      </div>
      <div class="col-md-4 col-lg-1 border border-sm">
      <p class=" text-center fs-5"> Forane</p>
      </div>
      <div class="col-md-4 col-lg-1  border border-sm">
      <p class=" text-center fs-5"> Unit</p>
      </div>
    </div>
    { list !== null ?
    list.map(
        (applicant)=>{
            return(
                <div  class=" w-100 p-1" key={applicant.id}>
                   <StudComp
   name={applicant.name}
   slno={applicant.id}
   mobile={applicant.mobile}
   competation={applicant.competations}
   unit={applicant.unit}
   forane={applicant.forane}
   class={applicant.class}
   email={applicant.email}
   gender={applicant.gender}
  />
   
                </div>
            ) 
        }
    ): null
    }
    </div>
  );
}
