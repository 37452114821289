import React from 'react'
import * as FileSaver from'file-saver';
import XLSX from 'sheetjs-style';
const ExportExcel = ({ excelData, fileName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = {Sheets: { 'data': ws },SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileExtension = '.xlsx';
    const exportToExcel = () => {FileSaver.saveAs(data, fileName + fileExtension);};
  
    return (
      
        <button variant="outline" class=" btn btn-primary" onClick={exportToExcel}  style={{ cursor: 'pointer', fontSize: 14 }}>
          DOWNLOAD EXCEL
        </button>
      
    );
  };
  
  export default ExportExcel;
  
  
  
  
  
  
  
  