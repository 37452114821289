
// import './App.css';
import Home from "./components/view/Home";
import Navbar from "./components/view/Navbar";
 import Footer from "./components/view/Footer";
 import Contact from "./components/view/Contact";
 import Registration from "./components/view/Registration";
 import CustomCard from "./components/custom components/CustomCard";
 import Courses from "./components/view/Courses";
import {BrowserRouter as Router,Route,Routes } from 'react-router-dom'; 
import About from "./components/view/About";
import Register1 from "./components/view/Register1";
import Enrolled from "./components/view/Enrolled";
function App() {
  return (
    <Router>
<div class="App bg-light">
<Navbar/>
<div>
  <Routes>
<Route path="/" element={<Home />}>
</Route>
<Route path="/about" element={<About />}/> 
<Route path="/contact" element={<Contact/>}/> 
<Route path="/register" element={<Register1 />}/>
<Route path="/participant_list" element={<Enrolled />}/>

</Routes> 
{/* <Register1/> */}
{/* <Courses/> */}

{/* <Enrolled/> */}
</div>

<Footer/>



</div>
</Router> 
    
  );
}

export default App;
