import React from 'react';
import CarouselComponent from'../custom components/CarouselComponent';
import CustomCard from '../custom components/CustomCard'
import CoursesCard from '../custom components/CoursesCard';
import Courses from './Courses';
import Registration from './Registration';
import'../css/Home.css';
import { Link ,Outlet} from 'react-router-dom';
// outlet tag used for rounting and nested routing passing props in side that route   <Outlet/>

export default function Home() {
  return (
    <div class="p-1">
      <div class="px-3  bg-white  " id="contact">      
</div>
    <CarouselComponent/>
    <div  class="  p-2 mt-5" id="containerinfo">    
         <div class="p-3 "  data-aos="fade-up"  data-aos-easing="ease-out-cubic"
     data-aos-duration="1500">
      <div class=" row">
        <div class=" col-sm-12 col-md-12 col-lg-6   d-flex  justify-content-center align-items-center" >
          
          <img src="/assets/what-we-stand-for.png" class=" img img-fluid w-75  h-75" alt="test image" id="aboutimg" />   
                   
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-6 p-3  d-flex  justify-content-center align-items-center" >
          <div class="me-3 what">       
          <p  class=" fs-1 fw-bold text-dark ">
          What We Stand For
         </p>
         <p  class=" fs-5  text-primary ">
         It is no secret that poverty is a major problem in India. The middle class seems to be disappearing and the gap between the upper class and the lower class is widening. Our mission is to break the cycle of poverty through education. We empower young children and their family for a better living.
         </p>
         <p  class=" fs-5  text-dark ">
         We are committed to helping students see possibilities beyond what they experience every day by empowering young students to achieve their aspirations of cracking entrance exams of medical and engineering, civil service exams and other job oriented competitive exams.
         </p>
         <a class="btn btn-outline-primary btn-lg text-decoration-none  "href="/">Join Us </a> 
         </div>
        </div>
      </div>
    </div>    
  </div>
    <Registration/>
  {/* <div  class="p-2">
  <div class="  row " id=" cards-container">
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>

  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
   </div>
</div> */}
 
</div>
  )
}
