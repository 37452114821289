import react from 'react';
import { Link,Outlet } from 'react-router-dom';
import'../css/navbar.css';
 function Navbar() {
  return (
   // fixed-top       for making scrollbar fixed at the top
   // sticky-top       for sticky component at tthe top
   <div>
 <nav class="navbar navbar-expand-lg  sticky-top shadow-sm  " id="navbar">
        <div class="container-fluid  " >         
         <Link class="navbar-brand my-1 " to="/">
        <img src="/assets/logos/dbclc-logo.png" alt="  university" width="80" height="80" class=" "/>
        </Link>   
        <p class="logotext "> Institute of Lay Leadership</p>        
        <button class="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon color-white"></span>
        </button> 
     <div class="  collapse navbar-collapse  " id="navbarNav">
      <ul class="navbar-nav ms-auto me-5">
       {/* ms-auto 
        me-5 "> */}
        <li class="nav-item   active px-3 ">
        <Link  class="nav-link active  fs-5  p-3 " to="/">Home</Link> 
        {/* <a href="/" class="nav-link active  fs-5   ">Home</a> */}
        </li>       
        <li class="nav-item px-3 ">
        <Link class="nav-link active fs-5  p-3" to="/about">About</Link> 
        {/* <a href="/" class="nav-link active  fs-5   ">About</a> */}
        </li>
        <li class="nav-item px-3">
          <Link class="nav-link active   fs-5  p-3" to="/contact">Contact</Link> 
          {/* <a href="/" class="nav-link active   fs-5 ">Contact</a> */}
        </li>
        {/* <li class="nav-item ">
          <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link> 
          <a href="/" class="nav-link active   fs-5">Faculty </a>
        </li>
        <li class="nav-item ">
          <Link class="nav-link active text-black  fs-4 fw-bold  font-monospace" to="/contact">Contact Us</Link> 
          <a href="/" class="nav-link active  fs-5  ">Student Material</a>
        </li>
       
        <li class="nav-item dropdown">
          <a class="nav-link  active fs-5  dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Coach Excel Educations
          </a>
          <ul class="dropdown-menu bg-light w-100 " aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item fs-6 "  href="/">Education</a></li>
            <li><a class="dropdown-item fs-6 " href="/">Promotion</a></li>
            <li><hr class="dropdown-divider active fs-5 fw-bold"/></li>
            <li><a class="dropdown-item fs-6 " href="/">Job</a></li>
          </ul>
        </li>
        */}
      </ul>   
    </div>
    </div>
  </nav>
  <div  class=" d-flex align-items-center bg-primary ">
        
          <marquee class="fs-6 text-white "> * Innovative Idea Meet 2023 . Participants who wants to register to this event 
            <span>   <Link class="  fs-5  text-info" to="/register">Click Here!</Link> </span>
          </marquee>
        
    </div>
 <Outlet/>
   </div>
     
 
  )
}
export default Navbar;